import React, { useState } from "react";
import { ethers } from "ethers";
import { Form, Button, Row, Col, Alert, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "./Spinner";

export function CreateProposal({
  cvContract,
  lockeBalance,
  onProposalCreated,
  lockeTokenContract,
  selectedAddress,
}) {
  const [description, setDescription] = useState("");
  const [expirationDate, setExpirationDate] = useState(null);
  const [proposalType, setProposalType] = useState("transfer"); // Default to transfer type
  const [recipient, setRecipient] = useState(""); // Recipient for transfer type
  const [amount, setAmount] = useState(1); // Amount for transfer type
  const [upgradeTarget, setUpgradeTarget] = useState(""); // Target contract for upgrade type
  const [error, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState(""); // Status message for process
  const [isSubmitting, setIsSubmitting] = useState(false); // Submission status

  const isValidEthereumAddress = (address) => {
    return ethers.utils.isAddress(address);
  };

  const validateForm = () => {
    if (!description) {
      setError("Description is required");
      return false;
    }
    if (proposalType === "transfer") {
      if (!recipient || !isValidEthereumAddress(recipient)) {
        setError("Valid recipient address is required");
        return false;
      }
      if (amount <= 0) {
        setError("Amount must be greater than 0");
        return false;
      }
    }
    if (expirationDate && expirationDate.getTime() <= Date.now()) {
      setError("Expiration date must be in the future");
      return false;
    }
    setError(null);
    return true;
  };

  async function createProposal() {
    if (!cvContract) return;

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    let callData;

    try {
      const allowance = await lockeTokenContract.allowance(
        selectedAddress,
        cvContract.address
      );
      console.log("Current allowance:", allowance.toString());

      const requiredAmount = ethers.utils.parseUnits(amount.toString(), 18);
      if (allowance.lt(requiredAmount)) {
        setStatusMessage("Approving tokens...");
        console.log("Approving tokens...");
        const approveTx = await lockeTokenContract.approve(
          cvContract.address,
          requiredAmount
        );
        await approveTx.wait();
        console.log("Tokens approved.");
        setStatusMessage("Tokens approved.");
      }

      let tx;
      if (proposalType === "transfer") {
        callData = ethers.utils.defaultAbiCoder.encode(
          ["address", "uint256"],
          [recipient, requiredAmount]
        );
        console.log("Creating transfer proposal with data:", {
          proposalType: 1,
          recipient,
          callData,
          description,
          expirationDate: expirationDate
            ? Math.floor(expirationDate.getTime() / 1000)
            : 0,
        });
        setStatusMessage("Creating transfer proposal...");
        tx = await cvContract.createProposal(
          1, // Transfer proposal type
          recipient,
          callData,
          description,
          expirationDate ? Math.floor(expirationDate.getTime() / 1000) : 0,
          {
            gasLimit: 3000000, // Increase gas limit
          }
        );
      } else {
        callData = ethers.utils.defaultAbiCoder.encode([], []);
        console.log("Creating upgrade proposal with data:", {
          proposalType: 0,
          upgradeTarget,
          callData,
          description,
          expirationDate: expirationDate
            ? Math.floor(expirationDate.getTime() / 1000)
            : 0,
        });
        setStatusMessage("Creating upgrade proposal...");
        tx = await cvContract.createProposal(
          0, // Upgrade proposal type
          upgradeTarget,
          callData,
          description,
          expirationDate ? Math.floor(expirationDate.getTime() / 1000) : 0,
          {
            gasLimit: 3000000, // Increase gas limit
          }
        );
      }
      await tx.wait();
      console.log(
        `${
          proposalType.charAt(0).toUpperCase() + proposalType.slice(1)
        } proposal created:`,
        description
      );
      setStatusMessage("Proposal created successfully.");
      onProposalCreated(); // Notify parent component to refresh proposals
      setTimeout(() => {
        setStatusMessage("");
        setIsSubmitting(false);
      }, 3000); // Clear status message after 3 seconds
    } catch (error) {
      console.error("Error creating proposal:", error);
      console.error("Transaction Data:", {
        proposalType,
        recipient,
        amount,
        upgradeTarget,
        description,
        expirationDate: expirationDate
          ? Math.floor(expirationDate.getTime() / 1000)
          : 0,
        callData,
      });
      setStatusMessage(`Error creating proposal: ${error.message || error}`);
      setIsSubmitting(false);
    }
  }

  if (!lockeBalance || lockeBalance.isZero()) {
    return (
      <div>
        <h2>Create Proposal</h2>
        <Alert variant="warning">
          You need LOCKE token to create a proposal.
        </Alert>
      </div>
    );
  }

  return (
    <div>
      <h2>Create Proposal</h2>
      <Container
        className="p-4"
        style={{ border: "1px solid lightgray", borderRadius: "10px" }}
      >
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Proposal Type
            </Form.Label>
            <Col sm={10}>
              <Form.Select
                value={proposalType}
                onChange={(e) => setProposalType(e.target.value)}
                className="form-control-lg"
                style={{ fontSize: "1.25rem", padding: "0.5rem" }}
              >
                <option value="transfer">Transfer</option>
                {/* <option value="upgrade">Upgrade</option> */}
              </Form.Select>
            </Col>
          </Form.Group>

          {proposalType === "transfer" && (
            <>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Recipient
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={recipient}
                    onChange={(e) => setRecipient(e.target.value)}
                    placeholder="Recipient Address"
                    className="form-control-lg"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Send Amount (LOCKE)
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Amount (LOCKE), e.g. 1"
                    className="form-control-lg"
                  />
                </Col>
              </Form.Group>
            </>
          )}

          {proposalType === "upgrade" && (
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                Upgrade Smart Contract
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  value={upgradeTarget}
                  onChange={(e) => setUpgradeTarget(e.target.value)}
                  placeholder="Upgrade Smart Contract ETH Address"
                  className="form-control-lg"
                />
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Description
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Proposal Description"
                className="form-control-lg"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Expiration Date
            </Form.Label>
            <Col sm={10}>
              <DatePicker
                selected={expirationDate}
                onChange={(date) => setExpirationDate(date)}
                showTimeSelect
                dateFormat="Pp"
                className="form-control form-control-lg"
                placeholderText="Select Calendar"
              />
            </Col>
          </Form.Group>

          {error && <Alert variant="danger">{error}</Alert>}
          {statusMessage && (
            <Alert variant="info">
              {statusMessage}
              {statusMessage !== "Proposal created successfully." && (
                <Spinner />
              )}
            </Alert>
          )}

          <Button
            variant="primary"
            className="btn-lg"
            onClick={createProposal}
            disabled={isSubmitting}
          >
            Create
          </Button>
        </Form>
      </Container>
    </div>
  );
}
