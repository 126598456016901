import React from "react";
import { Spinner } from "./Spinner";

export function WaitingForTransactionMessage({ txHash }) {
  return (
    <div className="alert alert-info d-flex align-items-center" role="alert">
      <div>
        Waiting for transaction <strong>{txHash}</strong> to be mined
      </div>
      <Spinner />
    </div>
  );
}
