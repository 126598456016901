// Spinner.js
import React from "react";

export const Spinner = () => (
  <div
    className="spinner-border spinner-border-sm text-primary ml-2"
    role="status"
  >
    <span className="sr-only">Loading...</span>
  </div>
);
