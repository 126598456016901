import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Dapp } from "./components/Dapp";
import { ConvictionVotingDapp } from "./components/ConvictionVotingDapp";

// We import bootstrap here, but you can remove if you want
import "bootstrap/dist/css/bootstrap.css";
import ImageLogo from "./components/ImageLogo";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <ImageLogo />
      <Routes>
        <Route path="/" element={<Dapp />} />
        <Route path="/cvoting" element={<ConvictionVotingDapp />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
