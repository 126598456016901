import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import ConvictionVotingArtifact from "../contracts/ConvictionVoting.json";
import TokenArtifact from "../contracts/Token.json";
import contractAddress from "../contracts/contract-address.json";
import { NoWalletDetected } from "./NoWalletDetected";
import { ConnectWallet } from "./ConnectWallet";
import { Loading } from "./Loading";
import { ProposalList } from "./ProposalList";
import { CreateProposal } from "./CreateProposal";

const SEPOLIA_NETWORK_ID = "11155111";

export function ConvictionVotingDapp() {
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [networkError, setNetworkError] = useState(undefined);
  const [cvContract, setCvContract] = useState(undefined);
  const [lockeTokenContract, setLockeTokenContract] = useState(undefined);
  const [lockeBalance, setLockeBalance] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", ([newAddress]) => {
        if (newAddress === undefined) {
          setSelectedAddress(undefined);
        } else {
          console.log("Account changed to:", newAddress);
          setSelectedAddress(newAddress);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedAddress) {
      console.log("Selected address updated in useEffect:", selectedAddress);
      initialize();
    }
  }, [selectedAddress]);

  if (!window.ethereum) {
    return <NoWalletDetected />;
  }

  if (!selectedAddress) {
    return (
      <ConnectWallet
        connectWallet={connectWallet}
        networkError={networkError}
        dismiss={() => setNetworkError(undefined)}
      />
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  async function connectWallet() {
    try {
      const [address] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log("Wallet connected, address:", address);
      setSelectedAddress(address);
      await switchNetwork(SEPOLIA_NETWORK_ID);
    } catch (error) {
      console.error("Error connecting to wallet:", error);
    }
  }

  async function initialize() {
    try {
      console.log(
        "Initializing contracts with selected address:",
        selectedAddress
      );
      if (!selectedAddress) {
        throw new Error("Selected address is not defined");
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      network.name =
        network.chainId === parseInt(SEPOLIA_NETWORK_ID, 10)
          ? "sepolia"
          : network.name;
      console.log("Connected network:", network);

      if (network.chainId !== parseInt(SEPOLIA_NETWORK_ID, 10)) {
        setNetworkError("Please connect to the Sepolia network");
        await switchNetwork(SEPOLIA_NETWORK_ID);
        return;
      }

      const { Token, ConvictionVoting } = contractAddress;

      if (!Token || !ConvictionVoting) {
        throw new Error("Contract addresses are not defined");
      }

      console.log("Token contract address:", Token);
      console.log("ConvictionVoting contract address:", ConvictionVoting);

      const signer = provider.getSigner();
      const cv = new ethers.Contract(
        ConvictionVoting,
        ConvictionVotingArtifact.abi,
        signer
      );
      const lockeToken = new ethers.Contract(Token, TokenArtifact.abi, signer);

      console.log("Contract instance created:", cv);
      console.log("LOCKE Token contract instance created:", lockeToken);

      // Fetch the LOCKE token balance
      const balance = await lockeToken.balanceOf(selectedAddress);
      console.log("LOCKE Token balance:", balance.toString());

      setCvContract(cv);
      setLockeTokenContract(lockeToken);
      setLockeBalance(balance);
      setIsLoading(false); // Set loading to false after initialization
      console.log("Contract initialized successfully");
    } catch (error) {
      console.error("Error initializing ConvictionVoting contract:", error);
      setNetworkError(
        "Error initializing ConvictionVoting contract: " + error.message
      );
      setIsLoading(false); // Set loading to false even if there's an error
    }
  }

  async function switchNetwork(chainId) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexValue(parseInt(chainId, 10)) }],
      });
      console.log("Network switched to Sepolia");
      // Do not call initialize here to avoid resetting the state
    } catch (error) {
      console.error("Error switching network:", error);
      setNetworkError(
        "Failed to switch network. Please switch to Sepolia network manually."
      );
    }
  }

  async function executeProposal(proposalId) {
    try {
      const tx = await cvContract.executeProposal(proposalId);
      await tx.wait();
      console.log("Proposal executed successfully", proposalId);
      // Refresh proposals list or update the UI as needed
    } catch (error) {
      console.error("Error executing proposal:", error);
      setNetworkError("Failed to execute proposal: " + error.message);
    }
  }

  return (
    <div className="container p-4">
      <h1>Conviction Voting DApp</h1>
      <p>
        Your LOCKE Token Balance:{" "}
        {lockeBalance
          ? ethers.utils.formatUnits(lockeBalance, 18)
          : "Loading..."}
      </p>
      <ProposalList
        cvContract={cvContract}
        lockeTokenContract={lockeTokenContract}
        selectedAddress={selectedAddress}
        lockeBalance={lockeBalance}
        onExecuteProposal={executeProposal}
      />
      <br />
      <CreateProposal
        cvContract={cvContract}
        lockeTokenContract={lockeTokenContract}
        selectedAddress={selectedAddress}
        lockeBalance={lockeBalance}
        onProposalCreated={initialize}
      />
    </div>
  );
}
