import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { ListGroup, Button, Alert } from "react-bootstrap";
import { Spinner } from "./Spinner";

export function ProposalList({
  cvContract,
  lockeTokenContract,
  selectedAddress,
  lockeBalance,
}) {
  const [proposals, setProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statusMessages, setStatusMessages] = useState({}); // Status messages for actions
  const [isSubmitting, setIsSubmitting] = useState(false); // Submission status

  const fetchProposals = async () => {
    try {
      const proposalCount = await cvContract.proposalCount();
      const proposalsArray = [];

      for (let i = 0; i < proposalCount; i++) {
        let proposal = await cvContract.proposals(i);
        proposal = { ...proposal }; // Create a mutable copy of the proposal

        // Check and update status in the frontend only
        const currentTime = Math.floor(Date.now() / 1000);
        if (
          proposal.expirationDate !== 0 &&
          proposal.expirationDate <= currentTime &&
          proposal.status === 0
        ) {
          if (proposal.votes.gte(proposal.quorum)) {
            proposal.status = 4; // Approved
          } else {
            proposal.status = 3; // Expired
          }
        }

        proposalsArray.push(proposal);
      }

      setProposals(proposalsArray);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching proposals:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cvContract) {
      fetchProposals();
      const interval = setInterval(fetchProposals, 5000); // Poll every 5 seconds
      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [cvContract]);

  const updateStatusMessage = (index, message) => {
    setStatusMessages((prev) => ({
      ...prev,
      [index]: message,
    }));
  };

  const clearStatusMessage = (index) => {
    setTimeout(() => {
      setStatusMessages((prev) => ({
        ...prev,
        [index]: "",
      }));
      setIsSubmitting(false);
    }, 3000); // Clear status message after 3 seconds
  };

  async function deleteProposal(proposalId) {
    try {
      setIsSubmitting(true);
      updateStatusMessage(proposalId, "Deleting proposal...");
      const tx = await cvContract.deleteProposal(proposalId);
      await tx.wait();
      fetchProposals(); // Refresh the proposals list
      console.log("Proposal deleted:", proposalId);
      updateStatusMessage(proposalId, "Proposal deleted successfully.");
    } catch (error) {
      console.error("Error deleting proposal:", error);
      updateStatusMessage(
        proposalId,
        `Error deleting proposal: ${error.message || error}`
      );
    } finally {
      clearStatusMessage(proposalId);
    }
  }

  async function voteProposal(proposalId) {
    try {
      setIsSubmitting(true);
      updateStatusMessage(proposalId, "Approving tokens...");
      const amount = ethers.utils.parseUnits("1", 18);

      // Check allowance
      const allowance = await lockeTokenContract.allowance(
        selectedAddress,
        cvContract.address
      );
      if (allowance.lt(amount)) {
        console.log("Approving tokens...");
        const txApprove = await lockeTokenContract.approve(
          cvContract.address,
          amount
        );
        await txApprove.wait();
        console.log("Tokens approved");
      }

      // Proceed to vote
      updateStatusMessage(proposalId, "Voting on proposal...");
      const tx = await cvContract.vote(proposalId, amount, {
        gasLimit: 200000,
      });
      await tx.wait();
      fetchProposals(); // Refresh the proposals list
      console.log("Voted on proposal:", proposalId);
      updateStatusMessage(proposalId, "Voted successfully.");
    } catch (error) {
      console.error("Error voting on proposal:", error);
      updateStatusMessage(
        proposalId,
        `Error voting on proposal: ${error.message || error}`
      );
    } finally {
      clearStatusMessage(proposalId);
    }
  }

  async function executeProposal(proposalId) {
    try {
      setIsSubmitting(true);
      updateStatusMessage(proposalId, "Executing proposal...");
      const gasEstimate = await cvContract.estimateGas.executeProposal(
        proposalId
      );
      console.log(`Estimated Gas: ${gasEstimate.toString()}`);

      const tx = await cvContract.executeProposal(proposalId, {
        gasLimit: gasEstimate.add(100000), // Adding extra gas to the estimate
      });
      await tx.wait();
      fetchProposals(); // Refresh the proposals list
      console.log("Proposal executed:", proposalId);
      updateStatusMessage(proposalId, "Proposal executed successfully.");
    } catch (error) {
      console.error("Error executing proposal:", error);
      console.error("Detailed error:", JSON.stringify(error, null, 2));
      updateStatusMessage(
        proposalId,
        `Error executing proposal: ${error.message || error}`
      );
    } finally {
      clearStatusMessage(proposalId);
    }
  }

  const getProposalType = (type) => {
    return type === 0 ? "Upgrade" : "Transfer";
  };

  const decodeCallData = (callData, type) => {
    if (type === 1) {
      const decoded = ethers.utils.defaultAbiCoder.decode(
        ["address", "uint256"],
        callData
      );
      return {
        recipient: decoded[0],
        amount: ethers.utils.formatUnits(decoded[1], 18),
      };
    }
    return {};
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return "Active";
      case 1:
        return "Executed";
      case 2:
        return "Canceled";
      case 3:
        return "Expired";
      case 4:
        return "Approved";
      default:
        return "Unknown";
    }
  };

  const formatQuorum = (quorum) => {
    return ethers.utils.formatUnits(quorum, 18);
  };

  return (
    <div>
      <h2>Proposals</h2>
      {isLoading ? (
        <p>Loading proposals...</p>
      ) : proposals.length === 0 ? (
        <p>There are no proposals yet.</p>
      ) : (
        <ListGroup>
          {proposals.map((proposal, index) => {
            const { recipient, amount } = decodeCallData(
              proposal.callData,
              proposal.proposalType
            );
            return (
              <ListGroup.Item key={index}>
                <h5>Proposal #{index + 1}</h5>
                <p>Type: {getProposalType(proposal.proposalType)}</p>
                <p>Description: {proposal.description}</p>
                <p>Status: {getStatus(proposal.status)}</p>
                <p>Votes: {ethers.utils.formatUnits(proposal.votes, 18)}</p>
                <p>Quorum: {formatQuorum(proposal.quorum)}</p>{" "}
                {/* Display quorum */}
                {proposal.proposalType === 1 && (
                  <>
                    <p>Recipient: {recipient}</p>
                    <p>Send Amount (LOCKE): {amount}</p>
                  </>
                )}
                <p>
                  Expiration Date:{" "}
                  {proposal.expirationDate
                    ? new Date(proposal.expirationDate * 1000).toLocaleString()
                    : "No expiration"}
                </p>
                {proposal.proposer === selectedAddress &&
                  getStatus(proposal.status) === "Active" && (
                    <Button
                      variant="danger"
                      onClick={() => deleteProposal(index)}
                      disabled={isSubmitting}
                    >
                      Delete Proposal
                    </Button>
                  )}
                {getStatus(proposal.status) === "Active" && (
                  <Button
                    variant="success"
                    onClick={() => voteProposal(index)}
                    disabled={isSubmitting}
                  >
                    Vote
                  </Button>
                )}
                {(getStatus(proposal.status) === "Expired" &&
                  proposal.votes.gte(proposal.quorum)) ||
                  (getStatus(proposal.status) === "Approved" && (
                    <Button
                      variant="primary"
                      onClick={() => executeProposal(index)}
                      disabled={isSubmitting}
                    >
                      Execute
                    </Button>
                  ))}
                {statusMessages[index] && (
                  <Alert variant="info" className="mt-2">
                    {statusMessages[index]}
                    {statusMessages[index] !==
                      "Proposal executed successfully." &&
                    statusMessages[index] !==
                      "Proposal deleted successfully." &&
                    statusMessages[index] !== "Voted successfully." ? (
                      <Spinner />
                    ) : null}
                  </Alert>
                )}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      )}
    </div>
  );
}
