import React from "react";

export function Transfer({ transferTokens, tokenSymbol }) {
  return (
    <div>
      <h4>Transfer</h4>
      <form
        onSubmit={(event) => {
          // This function just calls the transferTokens callback with the
          // form's data.
          event.preventDefault();

          const formData = new FormData(event.target);
          const to = formData.get("to");
          const amount = formData.get("amount");

          if (to && amount) {
            transferTokens(to, amount);
          }
        }}
      >
        <div className="form-group">
          <label>Amount of {tokenSymbol}</label>
          <input
            className="form-control"
            type="number"
            step="0.000000000000000001"
            name="amount"
            placeholder="e.g. 10"
            required
          />
        </div>
        <div className="form-group">
          <label>Recipient address</label>
          <input
            className="form-control"
            type="text"
            placeholder="Enter an ethereum address"
            name="to"
            required
          />
        </div>
        <div className="form-group">
          <input
            className="btn btn-primary"
            placeholder="e.g. 10"
            type="submit"
            value="Transfer"
          />
        </div>
      </form>
    </div>
  );
}
