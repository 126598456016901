import React from "react";

const ImageLogo = () => {
  return (
    <div
      style={{
        marginTop: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src="../logo.png" height="50px" alt="Logo" />
    </div>
  );
};

export default ImageLogo;
