import React from "react";
import { ethers } from "ethers";
import TokenV2Artifact from "../contracts/TokenV2.json"; // Updated to TokenV2
import contractAddress from "../contracts/contract-address.json";
import { NoWalletDetected } from "./NoWalletDetected";
import { ConnectWallet } from "./ConnectWallet";
import { Loading } from "./Loading";
import { Transfer } from "./Transfer";
import { TransactionErrorMessage } from "./TransactionErrorMessage";
import { WaitingForTransactionMessage } from "./WaitingForTransactionMessage";
import { NoTokensMessage } from "./NoTokensMessage";
import { Form, Button, Container, Alert } from "react-bootstrap";

const HARDHAT_NETWORK_ID = "11155111";
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

export class Dapp extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      tokenData: undefined,
      selectedAddress: undefined,
      balance: undefined,
      ownerBalance: undefined, // Added to store owner's balance
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
      isOwner: false, // State to track if the user is the owner
      newOwnerAddress: "", // State to store new owner address input
      transferAmount: 1, // State to store transfer amount input
      recipientAddress: "", // State to store recipient address input
    };
    this.state = this.initialState;
  }

  render() {
    if (window.ethereum === undefined) {
      return <NoWalletDetected />;
    }

    if (!this.state.selectedAddress) {
      return (
        <ConnectWallet
          connectWallet={() => this._connectWallet()}
          networkError={this.state.networkError}
          dismiss={() => this._dismissNetworkError()}
        />
      );
    }

    if (!this.state.tokenData || !this.state.balance) {
      return <Loading />;
    }

    return (
      <div className="container p-4">
        <div className="row">
          <div className="col-12">
            <h1>
              {this.state.tokenData.name} ({this.state.tokenData.symbol}){" "}
              <span style={{ color: "gray" }}>
                {this.state.tokenData.version}
              </span>
            </h1>
            <p>
              Welcome <b>{this.state.selectedAddress}</b>, you have{" "}
              <b>
                {ethers.utils.formatUnits(this.state.balance, 18)}{" "}
                {this.state.tokenData.symbol}
              </b>
              .
            </p>
            {this.state.isOwner ? (
              <p>You are the owner of the contract.</p>
            ) : (
              <p>You are not the owner of the contract.</p>
            )}
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-12">
            {this.state.txBeingSent && (
              <WaitingForTransactionMessage txHash={this.state.txBeingSent} />
            )}
            {this.state.transactionError && (
              <TransactionErrorMessage
                message={this._getRpcErrorMessage(this.state.transactionError)}
                dismiss={() => this._dismissTransactionError()}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Container
              className="p-4"
              style={{
                border: "1px solid lightgray",
                borderRadius: "10px",
              }}
            >
              {ethers.BigNumber.from(this.state.balance).eq(0) && (
                <NoTokensMessage selectedAddress={this.state.selectedAddress} />
              )}
              {ethers.BigNumber.from(this.state.balance).gt(0) && (
                <Transfer
                  transferTokens={(to, amount) =>
                    this._transferTokens(to, amount)
                  }
                  tokenSymbol={this.state.tokenData.symbol}
                />
              )}
            </Container>
          </div>
        </div>

        {/* {this.state.isOwner && (
          <div className="row mt-4">
            <div className="col-12 mb-3">
              <Container
                className="p-4"
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                }}
              >
                <h4>Transfer Ownership</h4>
                <Form>
                  <Form.Group controlId="newOwnerAddress">
                    <Form.Label>New Owner Address</Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.newOwnerAddress}
                      onChange={(e) =>
                        this.setState({ newOwnerAddress: e.target.value })
                      }
                      placeholder="Enter new owner address"
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="mt-3"
                    onClick={() => this._transferOwnership()}
                  >
                    Transfer Ownership
                  </Button>
                </Form>
              </Container>
            </div>
          </div>
        )} */}
      </div>
    );
  }

  componentWillUnmount() {
    this._stopPollingData();
  }

  async _connectWallet() {
    const [selectedAddress] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    this._checkNetwork();
    this._initialize(selectedAddress);
    window.ethereum.on("accountsChanged", ([newAddress]) => {
      this._stopPollingData();
      if (newAddress === undefined) {
        return this._resetState();
      }
      this._initialize(newAddress);
    });
  }

  _initialize(userAddress) {
    this.setState({
      selectedAddress: userAddress,
    });
    this._initializeEthers();
    this._getTokenData();
    this._startPollingData();
    this._listenForVersionUpdate();
    this._checkIfOwner();
  }

  async _initializeEthers() {
    this._provider = new ethers.providers.Web3Provider(window.ethereum);
    this._token = new ethers.Contract(
      contractAddress.Token,
      TokenV2Artifact.abi, // Updated to TokenV2 ABI
      this._provider.getSigner(0)
    );
  }

  _startPollingData() {
    this._pollDataInterval = setInterval(() => this._updateBalance(), 1000);
    this._updateBalance();
    this._updateOwnerBalance(); // Added to update owner's balance
  }

  _stopPollingData() {
    clearInterval(this._pollDataInterval);
    this._pollDataInterval = undefined;
  }

  async _getTokenData() {
    const name = await this._token.name();
    const symbol = await this._token.symbol();
    let version;
    try {
      version = await this._token.getVersion(); // Fetch version from TokenV2
      console.log("getVersion function", version);
    } catch (error) {
      console.error("getVersion function is not available", error);
      version = " "; // Fallback version
    }
    this.setState({ tokenData: { name, symbol, version } });
  }

  async _updateBalance() {
    const balance = await this._token.balanceOf(this.state.selectedAddress);
    this.setState({ balance });
  }

  async _updateOwnerBalance() {
    const ownerBalance = await this._token.balanceOf(await this._token.owner());
    this.setState({ ownerBalance });
  }

  async _transferTokens(to, amount) {
    try {
      this._dismissTransactionError();
      const tx = await this._token.transfer(
        to,
        ethers.utils.parseUnits(amount, 18) // Convert amount to 18 decimals
      );
      this.setState({ txBeingSent: tx.hash });
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      await this._updateBalance();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }

  async _transferOwnership() {
    try {
      this._dismissTransactionError();
      const tx = await this._token.transferOwnership(
        this.state.newOwnerAddress
      );
      this.setState({ txBeingSent: tx.hash });
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      await this._checkIfOwner(); // Update the owner status
      await this._updateOwnerBalance(); // Update owner's balance
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }

  _dismissTransactionError() {
    this.setState({ transactionError: undefined });
  }

  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }
    return error.message;
  }

  _resetState() {
    this.setState(this.initialState);
  }

  async _switchChain() {
    const chainIdHex = `0x${HARDHAT_NETWORK_ID.toString(16)}`;
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainIdHex }],
    });
    await this._initialize(this.state.selectedAddress);
  }

  _checkNetwork() {
    if (window.ethereum.networkVersion !== HARDHAT_NETWORK_ID) {
      this._switchChain();
    }
  }

  _listenForVersionUpdate() {
    this._token.on("VersionUpdated", (version) => {
      this.setState((prevState) => ({
        tokenData: {
          ...prevState.tokenData,
          version,
        },
      }));
    });
  }

  async _checkIfOwner() {
    const owner = await this._token.owner();
    const isOwner =
      this.state.selectedAddress.toLowerCase() === owner.toLowerCase();
    this.setState({ isOwner });
  }
}
